import React, { useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";

const CheckOut = ({ resDetails, handleSubmit }) => {
  const userOrderAmount = localStorage.getItem("orderAmount");
  const userName = localStorage.getItem("username");
  const userPhoneNo = localStorage.getItem("mobileNo");
  const paymentSession = localStorage.getItem("paymentSessionId");

  useEffect(() => {
    // Dynamically load the Cashfree SDK script
    const script = document.createElement("script");
    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Check if Cashfree is available in the global scope
      if (window.Cashfree) {
        const cashfree = window.Cashfree({
          mode: "production", // or "sandbox" based on your environment
        });

        // Add click event listener for the payment button
        document.getElementById("renderBtn").addEventListener("click", () => {
          const paymentSessionId = paymentSession; // Replace with actual session ID

          // Call the Cashfree checkout method
          cashfree.checkout({ paymentSessionId }).then((result) => {
            if (result.error) {
              console.log("User has closed the popup or there is some payment error.");
              console.log(result.error);
            }
            if (result.redirect) {
              console.log("Payment will be redirected.");
            }
            if (result.paymentDetails) {
              console.log("Payment has been completed.");
              console.log(result.paymentDetails.paymentMessage);
            }
          });
        });
      } else {
        console.error("Cashfree SDK is not loaded correctly.");
      }
    };

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5", // Light background color
      }}
    >
      <Box
        sx={{
          backgroundColor: "#ffffff", // White background for the checkout box
          p: 4,
          borderRadius: 3,
          boxShadow: 4,
          width: "100%",
          maxWidth: "500px", // Make the box responsive
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            color: "#333",
            fontWeight: 900,
            textAlign: "center", // Center the title
            marginBottom: "30px",
          }}
          gutterBottom
        >
          Confirm Your Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#666",
                }}
              >
                Name :
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {userName}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#666",
                }}
              >
                Phone :
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: 700,
                  color: "#333",
                }}
              >
                {userPhoneNo}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#666",
                }}
              >
                Pay Amount :
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  color: "#27ae60", // Green color for the amount
                }}
              >
                ₹{userOrderAmount}
              </Typography>
            </Box>

            <Box sx={{ display:"flex", justifyContent:'space-between', marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  backgroundColor: "#3498db", // Cool blue color
                  "&:hover": {
                    backgroundColor: "#2980b9", // Darker blue on hover
                  },
                }}
              >
                Back
              </Button>
              <Button
                id="renderBtn"
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  backgroundColor: "#3498db", // Cool blue color
                  "&:hover": {
                    backgroundColor: "#2980b9", // Darker blue on hover
                  },
                }}
              >
                Confirm & Pay
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CheckOut;
